import { mapState } from "vuex";
//import { eventBus } from "./../../../../main";

export default {
  data: () => ({
    hasInfoIdsArrayForTooltip: ["filteredCampsitesGeoJson"],
    tooltip: null
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      filteredCampsitesGeoJson: state => state.trail.filteredCampsitesGeoJson
    })
  },

  methods: {
    createTooltip(e) {
      try {
        this.clearTooltip();

        if (this.map.getZoom() < this.appSettings.layerSetup.campsite.labelZoomShow) {
          let point = e.features[0];
          let coordinates = point?.geometry?.coordinates?.slice();
          let title = point?.properties?.title;

          //console.log("hover", { coordinates, title });

          this.tooltip = new window.mapboxgl.Popup({
            className: "campTooltipWrapper",
            closeButton: false,
            anchor: "center"
          })
            .setLngLat(coordinates)
            .setHTML(`<div class="hhToolTip">${title}</div>`)
            .addTo(this.map);
        }
      } catch (error) {
        console.error({ error });
      }
    },
    clearTooltip() {
      try {
        if (this.tooltip) {
          this.tooltip.remove();
          this.tooltip = null;
        }
      } catch (error) {
        //do nothing;
      }
    },
    initializeTooltipsEvents() {
      this.hasInfoIdsArrayForTooltip.forEach(layerId => {
        if (layerId) {
          this.map.on("mousemove", layerId, e => {
            try {
              this.createTooltip(e);
            } catch (error) {
              //do nothing
            }
          });

          // Change it back to a pointer when it leaves.
          this.map.on("mouseleave", layerId, () => {
            this.clearTooltip();
          });
        }
      });
    }
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {}
};
