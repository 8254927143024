<template>
  <v-card
    elevation="6"
    x_shaped
    v-if="ready"
    class="pb-1"
    :color="'success'"
    tile
  >
    <div
      v-if="!(properties.media_urls && properties.media_urls.length)"
      style="height:0;width:100%;overflow: visible; text-align: right;z-index:100; position:absolute"
    >
      <v-btn
        elevation="0"
        @click="clearAllPopups"
        class="ma-0"
        icon
        color="black"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <v-btn
      v-if="properties.media_urls && properties.media_urls.length"
      @click="loadMediaGallery"
      elevation="0"
      tile
      small
      color="white"
      class="floating-fullscreen-btn"
    >
      <v-icon>mdi-arrow-expand-all</v-icon>
    </v-btn>

    <v-btn
      v-if="properties.media_urls && properties.media_urls.length"
      @click="clearAllPopups"
      elevation="0"
      tile
      small
      color="white"
      class="floating-close-btn"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <PopupGallery
      v-if="properties.media_urls && properties.media_urls.length"
      :media_urls="properties.media_urls"
      :properties="properties"
    >
    </PopupGallery>

    <v-card-title v-if="properties.title" class="pt-1 mb-1 pb-1">
      {{ properties.title }}
      <font v-if="properties.id || properties.id == 0"
        >({{ properties.id }})</font
      >
    </v-card-title>

    <editButton class="floating-edit-btn " :id="properties.postId"></editButton>

    <template
      v-if="!isCampsite && layerId == 'hh-poi-layer' && properties.content"
    >
      <v-card-text>
        <popupContentBlock
          :content="properties.content"
          whereShow="popup"
        ></popupContentBlock>
      </v-card-text>
    </template>

    <template v-if="!isCampsite && layerId != 'hh-poi-layer'">
      <nonCampsitePopup
        :layerId="layerId"
        :properties="properties"
        :functions="functions"
      ></nonCampsitePopup>
    </template>
    <template v-if="isCampsite">
      <v-card-subtitle v-if="properties.campsite_closed" class="pb-1">
        (Camp Site Closed)
      </v-card-subtitle>

      <v-card-text class="pb-2">
        <CampSiteDetails
          :properties="properties"
          whereShow="popup"
        ></CampSiteDetails>
      </v-card-text>

      <v-card-text class="pt-0 pb-1">
        <PopupDistToBlock
          :properties="properties"
          v-if="properties && properties.nextCampStats"
        ></PopupDistToBlock>
      </v-card-text>

      <v-card-text class="py-1" v-if="properties.pointType">
        <v-btn
          v-if="
            !properties.campsite_closed &&
              properties.pointType != 'finish' &&
              properties.pointType != 'start'
          "
          :class="{
            'checkbox-btn--checked': skipCampById.includes(properties.id),
            'checkbox-btn--unchecked': !skipCampById.includes(properties.id)
          }"
          @click="functions.handleSkipCheckboxChangePopup(properties.id)"
          class="checkbox-btn"
          elevation="0"
          x-small
        >
          <v-icon left v-if="skipCampById.includes(properties.id)"
            >mdi-checkbox-marked</v-icon
          >
          <v-icon left v-else>mdi-checkbox-blank-outline</v-icon>
          Skip This Camp
        </v-btn>
      </v-card-text>
    </template>
    <mediaButtons
      :acfMedia="properties.acfMedia"
      :media_urls="properties.media_urls"
      :properties="properties"
    ></mediaButtons>
  </v-card>
</template>

<script>
import Vue from "vue";

import { eventBus } from "./../../../../main";

import mediaButtons from "./../../../media/mediaButtons";

import editButton from "./../../../ui/editButton.vue";

import PopupDistToBlock from "./PopupDistToBlock";
import nonCampsitePopup from "./nonCampsitePopup";
import PopupGallery from "./PopupGallery";
import CampSiteDetails from "./../../../infoblocks/CampSiteDetails";
import popupContentBlock from "./../../../infoblocks/popupContentBlock";
import "vue-json-pretty/lib/styles.css";
export default Vue.extend({
  components: {
    editButton,
    nonCampsitePopup,
    popupContentBlock,
    CampSiteDetails,
    PopupGallery,
    mediaButtons,
    PopupDistToBlock
  },
  data: () => ({
    width: 300,
    ready: false,
    propsToList: [""],
    showDetails: false,
    model: 0,
    colors: ["primary", "secondary", "yellow darken-2", "red", "orange"]
  }),

  props: {
    //goToPointByPointName: Function,

    isCampsite: Boolean,
    layerId: String,
    clearAllPopups: Function,
    functions: Object,
    appSettings: Object,
    maplayerdata: Object,
    skipCampById: Array,
    properties: Object,
    vuetifyCopy: {
      required: true,
      type: Object
    },
    browserDetectCopy: {
      required: true,
      type: Object
    }
  },

  computed: {
    isIOS() {
      var userAgent = window.navigator.userAgent;
      let isIpad = false;
      if (
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/Macintosh/i)
      ) {
        isIpad = true;
      } else {
        // Anything else
      }

      if (
        this.$browserDetect.isSafari ||
        this.$browserDetect.isIOS ||
        this.$browserDetect.isChromeIOS ||
        isIpad
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.$vuetify = this.vuetifyCopy;
    this.$browserDetect = this.browserDetectCopy;

    try {
      this.properties = JSON.parse(this.properties);
    } catch (error) {
      //do nothing
    }

    let convert = [
      "media_urls",
      "acfAmenities",
      "acfTrack",
      "acfCampsite",
      "acfMedia",
      "acfOther",
      "attachment_urls",
      "media",
      "legStats",
      "lastCampStats",
      "nextCampStats"
    ];

    convert.forEach(row => {
      try {
        if (this.properties[row]) {
          this.properties[row] = JSON.parse(this.properties[row]);
        }
      } catch (error) {
        //do nothing
      }
    });

    try {
      this.properties = JSON.parse(JSON.stringify(this.properties));
    } catch (error) {
      //do nothing
    }
    try {
      delete this.properties.legLineString;
    } catch (error) {
      //do nothing
    }

    this.ready = true;
  },

  methods: {
    loadMediaGallery() {
      eventBus.$emit("loadMediaDialog", {
        type: {
          prop: "gallery",
          title: "gallery",
          icon: "mdi-image-multiple",
          single: "Gallery"
        },
        title: "gallery",
        markertitle: this.properties.title,
        gallery: this.properties.media_urls
      });
    }
  }
});
</script>

<style>
.mapWrapper #mapContainer .mapboxgl-popup-wrapper {
  z-index: 15;
}

.floating-edit-btn {
  position: absolute;
  top: 36px; /* Adjust this as per your preference */
  right: 0; /* Adjust this as per your preference */
  z-index: 1; /* Ensure it's above the carousel */
}

.floating-close-btn {
  position: absolute;
  top: 0; /* Adjust this as per your preference */
  right: 0; /* Adjust this as per your preference */
  z-index: 1; /* Ensure it's above the carousel */
}
.floating-fullscreen-btn {
  position: absolute;
  top: 0; /* Adjust this as per your preference */
  left: 0; /* Adjust this as per your preference */
  z-index: 1; /* Ensure it's above the carousel */
}

.moreInfoButton {
}

.mapboxgl-popup-anchor-bottom {
  margin-bottom: 5px;
}

.mapboxgl-popup-content {
  box-shadow: none;
}
.galPopup,
.galPopup .mapboxgl-popup-content {
  background: transparent;
  padding: 0;
}

.galPopup {
  height: 270px;
}
.galPopup .mapboxgl-popup-content .v-card {
  min-height: 90px;
  width: 240px;
  width: 360px;
  max-width: 95vw;
}

#vue-popup-content,
.xgalPopup,
.xhhPopupAll,
.xgalPopup {
  height: 450px;
  min-height: 450px;
}

/*
tooltip stuff 
*/
.campTooltipWrapper {
  margin-top: 30px;
}
.campTooltipWrapper .mapboxgl-popup-content {
  border-radius: 4px;
  padding: 0 4px;
  text-align: center;
}

.campTooltipWrapper .mapboxgl-popup-tip {
  display: none;
}

.hhToolTip {
  padding: 0;
  margin: 0;
}
</style>
