//import _, { initial } from "lodash";
import { mapState } from "vuex";
const turf = require("@turf/turf");
import { eventBus } from "./../../../../main";

let pathZoom = ["interpolate", ["linear"], ["zoom"], 6, 1, 10, 4, 14, 4, 18, 6];

let factor = 1.15;
let pathZoomMain = [
  "interpolate",
  ["linear"],
  ["zoom"],
  6,
  1 * factor,
  10,
  4 * factor,
  14,
  4 * factor,
  18,
  6 * factor
];

let pathZoomBacking = [
  "interpolate",
  ["linear"],
  ["zoom"],
  6,
  1,
  10,
  4,
  14,
  4,
  18,
  6
];
var scaleFactor = 16;

const iconScaling = [
  "interpolate",
  ["linear"],
  ["zoom"],
  6,
  0.3 * scaleFactor,

  9.5,
  0.7 * scaleFactor,
  11,
  1 * scaleFactor,
  16,
  1.5 * scaleFactor
];

var textscaleFactor = 20;

const iconTextScaling = [
  "interpolate",
  ["linear"],
  ["zoom"],
  6,
  0.3 * textscaleFactor,

  9.5,
  0.7 * textscaleFactor,
  11,
  1 * textscaleFactor,
  16,
  2 * textscaleFactor
];

var fitBoundsToBBTimeout = null;
export default {
  data: () => ({
    //layerState: false
    currentPopup: null,
    pathsInitialized: false
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,

      initalTrackLineString: state => state.trail.initalTrackLineString,
      initalCampsitesGeoJson: state => state.trail.initalCampsitesGeoJson,
      filteredCampsitesGeoJson: state => state.trail.filteredCampsitesGeoJson,
      filteredTrackLineString: state => state.trail.filteredTrackLineString
    }),
    boundBoxOfTrack() {
      let bbox = null; //turf.bbox(this.filteredTrackLineString);
      try {
        bbox = turf.bbox(this.filteredTrackLineString);
      } catch (error) {
        //do nothing;
      }

      // Fit map to bounding box
      //

      return bbox;
    }
  },

  methods: {
    initializePath() {
      this.map.addSource("initalTrackLineString", {
        type: "geojson",
        data: this.initalTrackLineString
      });

      this.map.addSource("initalCampsitesGeoJson", {
        type: "geojson",
        data: this.initalCampsitesGeoJson
      });

      this.map.addSource("filteredCampsitesGeoJson", {
        type: "geojson",
        data: this.filteredCampsitesGeoJson
      });

      this.map.addSource("filteredTrackLineString", {
        type: "geojson",
        data: this.filteredTrackLineString
      });

      this.map.addLayer({
        id: "initalCampsitesGeoJson",
        type: "circle",
        source: "initalCampsitesGeoJson",
        paint: {
          "circle-radius": 8,
          "circle-color": "#e3e3e3"
        }
      });

      this.map.addLayer({
        id: "initalTrackLineString",
        type: "line",
        source: "initalTrackLineString",
        layout: {
          "line-join": "round",
          "line-cap": "round"
        },
        paint: {
          "line-color": "#e3e3e3",
          "line-width": pathZoomBacking
        }
      });

      this.map.addLayer({
        id: "BackgroundDashTrackLineString",
        type: "line",
        source: "initalTrackLineString",
        layout: {
          "line-join": "round",
          "line-cap": "round"
        },
        paint: {
          "line-color": "#0a744c",
          "line-width": pathZoom,
          "line-opacity": 0.7,
          "line-dasharray": [3, 3]
        }
      });

      this.map.addLayer({
        id: "BackgroundFilteredTrackLineString",
        type: "line",
        source: "filteredTrackLineString",
        layout: {
          "line-join": "round",
          "line-cap": "round"
        },
        paint: {
          "line-color": "#e3e3e3",
          "line-width": pathZoomBacking
        }
      });

      this.map.addLayer({
        id: "filteredTrackLineString",
        type: "line",
        source: "filteredTrackLineString",
        layout: {
          "line-join": "round",
          "line-cap": "round"
        },
        paint: {
          "line-color": "hsl(359, 66%, 33%)",
          "line-width": pathZoomMain,
          "line-dasharray": [4, 4]
        }
      });

      this.map.addLayer({
        id: "filteredCampsitesGeoJson",
        type: "circle",
        source: "filteredCampsitesGeoJson",
        paint: {
          "circle-radius": iconScaling,
          "circle-color": [
            "match",
            ["get", "pointType"],
            "start",
            "rgba(82 ,168 ,103, 0.90)", // Color for "start" points
            "skipped",
            "rgba(255, 165, 0, 0.90)", // Color for "start" points
            "finish",
            "rgba(207, 123, 123, 0.90)", // Color for "finish" points
            "normal",
            "rgba(0, 120, 255, 0.80)", // Color for "normal" points
            "gray" // Default color if "pointType" doesn't match any of the above
          ]
        }
      });

      this.map.addLayer({
        id: "filteredCampsitesGeoJson-label",
        type: "symbol",
        source: "filteredCampsitesGeoJson",
        layout: {
          //"icon-allow-overlap": true, // Allow markers to overlap
          //"icon-ignore-placement": true,
          //"text-allow-overlap": true, // Allow text to overlap
          //"text-ignore-placement": true,
          "icon-padding": [
            "interpolate",
            ["linear"],
            ["zoom"],
            this.appSettings.layerSetup.campsite.labelZoomStart,
            10, // Text fully hidden at zoom 16
            this.appSettings.layerSetup.campsite.labelZoomShow,
            40 // Text fully visible at zoom 16.5
          ], // Reduce padding to allow tighter placement
          "text-padding": [
            "interpolate",
            ["linear"],
            ["zoom"],
            this.appSettings.layerSetup.campsite.labelZoomStart,
            10, // Text fully hidden at zoom 16
            this.appSettings.layerSetup.campsite.labelZoomShow,
            40 // Text fully visible at zoom 16.5
          ],

          "text-field": ["get", "id"],
          "text-size": iconTextScaling,
          "text-offset": [0, 0.6], // Adjust as needed to position text
          "text-anchor": "bottom" // Adjust text-anchor to position text properly
        },
        paint: {
          "text-color": "#000", // Text color
          "text-halo-color": "#fff", // Halo color
          "text-halo-width": 1, // Halo width
          "text-halo-blur": 1 // Halo blur
        }
      });

      this.map.addLayer(
        {
          id: "filteredCampsitesGeoJson-title",
          source: "filteredCampsitesGeoJson",
          type: "symbol",
          minzoom: 7,
          layout: {
            "icon-allow-overlap": true, // Allow markers to overlap
            //    'icon-ignore-placement': true,
            "text-allow-overlap": true, // Allow text to overlap
            //    'text-ignore-placement': true,
            "icon-padding": 0, // Reduce padding to allow tighter placement
            "text-padding": 0,

            "text-field": ["get", "title"], // Display title from properties
            "text-size": 16,
            "text-anchor": "top",
            "text-offset": [0, 1.5] // Position text above the marker
          },
          paint: {
            "text-color": "#000", // Text color
            "text-halo-color": "#fff", // Halo color
            "text-halo-width": 1, // Halo width
            "text-halo-blur": 1, // Halo blur

            "text-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              this.appSettings.layerSetup.campsite.labelZoomStart,
              0, // Text fully hidden at zoom 16
              this.appSettings.layerSetup.campsite.labelZoomShow,
              1 // Text fully visible at zoom 16.5
            ],
            "icon-opacity": 1 // Marker always visible
          }
        },
        this.poiSitUnderLayer
      );

      this.pathsInitialized = true;

      this.initializeTrackChainageLayer();
    },
    focusOnTrail() {
      let bbox = this.boundBoxOfTrack;
      if (this.pathsInitialized) {
        let { padding } = this.appSettings.map.defaultSetup;

        if (padding) {
          this.map.setPadding(padding);
        }

        try {
          if (fitBoundsToBBTimeout) {
            clearTimeout(fitBoundsToBBTimeout);
          }
        } catch (error) {
          //do nothing
        }

        fitBoundsToBBTimeout = setTimeout(() => {
          this.map.fitBounds(
            [
              [bbox[0], bbox[1]],
              [bbox[2], bbox[3]]
            ],
            { padding }
          );
        }, 100);
      }
    }
  },
  destroyed() {
    eventBus.$off("mapPath_focusOnTrail");
  },
  mounted() {
    eventBus.$on("mapPath_focusOnTrail", () => {
      this.focusOnTrail();
    });
  },
  watch: {
    initalTrackLineString: function(newValue) {
      if (this.pathsInitialized) {
        this.map.getSource("initalTrackLineString").setData(newValue);
      }
    },
    initalCampsitesGeoJson: function(newValue) {
      if (this.pathsInitialized) {
        this.map.getSource("initalCampsitesGeoJson").setData(newValue);
      }
    },
    filteredCampsitesGeoJson: function(newValue) {
      if (this.pathsInitialized) {
        this.map.getSource("filteredCampsitesGeoJson").setData(newValue);

        if (
          !isNaN(this.currentPopupId) &&
          newValue.features.filter(row => row.id == this.currentPopupId)
            .length == 0
        ) {
          this.clearAllPopups("Z");
        }
      }
    },
    filteredTrackLineString: function(newValue) {
      if (this.pathsInitialized) {
        this.map.getSource("filteredTrackLineString").setData(newValue);
      }
    },
    boundBoxOfTrack: function(bbox) {
      if (bbox) {
        //do nothing;
      }
    }
  }
};
