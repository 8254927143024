//import _, { initial } from "lodash";
import { mapState } from "vuex";

//import { eventBus } from "./../../../../main";

const iconScaling = [
  "interpolate",
  ["linear"],
  ["zoom"],
  9.5,
  0.7,
  11,
  0.65,
  14,
  1.7
];

export default {
  data: () => ({
    poiInitialized: true,
    poiLayerName: "hh-poi-layer",
    poiLayerLabelName: "hh-trackChainage-Labels",
    poiSitUnderLayer: "filteredCampsitesGeoJson"
  }),

  computed: {
    ...mapState({
      poiItemsGeoJson: state => state.mapsetupdate.poiItemsGeoJson,
      appSettings: state => state.global.appSettings,
    })
  },

  methods: {
    initializePoi() {
      console.log("initializePoi");

      console.log(this.poiItemsGeoJson);
      this.hasInfoIdsArrayForTooltip.push(this.poiLayerName);
      this.poiLayerIdForPopup.push(this.poiLayerName);
      this.map.addSource(this.poiLayerName, {
        type: "geojson",
        data: this.poiItemsGeoJson
      });

      this.map.addLayer(
        {
          id: this.poiLayerName,
          source: this.poiLayerName,
          type: "symbol",
          minzoom: 10,
          layout: {
            "icon-allow-overlap": true, // Allow markers to overlap
            //    'icon-ignore-placement': true,
            "text-allow-overlap": true, // Allow text to overlap
            //    'text-ignore-placement': true,
            "icon-padding": 0, // Reduce padding to allow tighter placement
            "text-padding": 0,

            "icon-image": ["get", "markerimageid"],
            "icon-size": iconScaling,
            "text-field": ["get", "title"], // Display title from properties
            "text-size": 16,
            "text-anchor": "top",
            "text-offset": [0, 1.5] // Position text above the marker
          },
          paint: {
            "text-color": "#000", // Text color
            "text-halo-color": "#fff", // Halo color
            "text-halo-width": 1, // Halo width
            "text-halo-blur": 1, // Halo blur

            "text-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              this.appSettings.layerSetup.poi.labelZoomStart,
              0, // Text fully hidden at zoom 16
              this.appSettings.layerSetup.poi.labelZoomShow,
              1 // Text fully visible at zoom 16.5
            ],
            "icon-opacity": 1 // Marker always visible
          }
        },
        this.poiSitUnderLayer
      );
    }
  },
  destroyed() {
    //eventBus.$off("mapPath_focusOnTrail");
  },
  mounted() {},
  watch: {
    poiItemsGeoJson: function(newValue) {
      if (this.poiInitialized) {
        this.map.getSource(this.poiLayerName).setData(newValue);
      }
    }
  }
};
