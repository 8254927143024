<template>
  <div>
    <div v-if="!allSets.length" style="width:200px">
      <v-progress-linear indeterminate color="red"></v-progress-linear>
    </div>
    <div
      id="trailControlWrapper"
      v-if="allSets.length"
      class="controlbox"
      :class="
        (!openMobileMenu && $vuetify.breakpoint.smAndDown) ||
        (!openDesktopMenu && !$vuetify.breakpoint.smAndDown)
          ? 'closedMenu'
          : 'openMenu'
      "
    >
      <center v-if="!openMobileMenu && $vuetify.breakpoint.smAndDown">
        <v-btn
          @click="openMobileMenu = !openMobileMenu"
          small
          icon
          class="floating-close-btn doubleSqurebutton"
        >
          <v-icon>mdi-filter-cog</v-icon>
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </center>

      <center v-if="!openDesktopMenu && !$vuetify.breakpoint.smAndDown">
        <v-btn
          @click="openDesktopMenu = !openDesktopMenu"
          small
          icon
          class="floating-close-btn doubleSqurebutton"
        >
          <v-icon>mdi-filter-cog</v-icon>
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </center>

      <div
        class="d-flex flex-row flex-wrap"
        v-if="
          (openMobileMenu && $vuetify.breakpoint.smAndDown) ||
            (openDesktopMenu && !$vuetify.breakpoint.smAndDown)
        "
      >
        <v-btn
          v-if="$vuetify.breakpoint.smAndDown"
          @click="openMobileMenu = !openMobileMenu"
          small
          icon
          class="floating-close-btn"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn
          v-if="!$vuetify.breakpoint.smAndDown"
          @click="openDesktopMenu = !openDesktopMenu"
          small
          icon
          class="floating-close-btn"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <div id="filterSections" class="filterItem mr-2">
          <v-select
            dense
            item-text="name"
            item-value="index"
            v-model="currentSectionIndex"
            :items="Object.values(sectionSetLookup)"
            label="Sections"
          >
          </v-select>
        </div>

        <div id="filterDirection" class="filterItem mr-2">
          <v-select
            dense
            :items="directionBooleanOptions"
            v-model="orderForward"
            label="Direction"
            item-text="label"
            item-value="value"
          >
          </v-select>
        </div>

        <div id="filterSkip" class="filterItem mr-2">
          <v-select
            dense
            v-model="skipCampById"
            :items="campsitesArray"
            item-text="label"
            item-value="id"
            label="Skip Camps"
            multiple
          >
            <template v-slot:selection="{ item, index }">
              <span
                v-if="skipCampById && skipCampById.length && index === 0"
                class="black--text text-caption"
              >
                {{ currentActiveSkip.length }} Skipped
              </span>
            </template>
          </v-select>
        </div>

        <div
          id="filterStart"
          class="filterItem startFinish d-flex flex-row mr-2"
        >
          <v-select
            dense
            item-text="label"
            item-value="orderIndex"
            v-model="fromCampOrderIndex"
            :items="Object.values(campsitesLookup)"
            label="Start"
          >
            <!-- Customize dropdown items -->
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                @click="
                  update(
                    'start',
                    JSON.parse(JSON.stringify(fromCampOrderIndex))
                  )
                "
              >
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-select>

          <v-btn
            class="forwardBackButtons"
            :disabled="!campsitesLookup[fromCampOrderIndex - 1]"
            icon
            x-small
            color="black"
            @click="fromCampOrderIndex--"
          >
            <v-icon>mdi-step-backward</v-icon>
          </v-btn>

          <v-btn
            class="forwardBackButtons"
            icon
            x-small
            color="black"
            @click="fromCampOrderIndex++"
          >
            <v-icon>mdi-step-forward</v-icon>
          </v-btn>
        </div>

        <div
          id="filterFinish"
          class="filterItem startFinish d-flex flex-row mr-2"
        >
          <v-select
            dense
            item-text="label"
            item-value="orderIndex"
            v-model="toCampOrderIndex"
            :items="Object.values(campsitesLookup)"
            label="Finish"
          >
            <!-- Customize dropdown items -->
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                @click="
                  update('finish', JSON.parse(JSON.stringify(toCampOrderIndex)))
                "
              >
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-select>

          <v-btn
            class="forwardBackButtons"
            icon
            x-small
            color="black"
            @click="toCampOrderIndex--"
          >
            <v-icon>mdi-step-backward</v-icon>
          </v-btn>

          <v-btn
            class="forwardBackButtons"
            :disabled="!campsitesLookup[toCampOrderIndex + 1]"
            icon
            x-small
            color="black"
            @click="toCampOrderIndex++"
          >
            <v-icon>mdi-step-forward</v-icon>
          </v-btn>
        </div>

        <div>
          <v-checkbox
            v-model="showCustomStartFinish"
            :disabled="enableDraggableCustomStopFinishDisabled"
            :label="`Show Draggable Custom Start and Finish Controls`"
          ></v-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { eventBus } from "../../main";
import { mapState } from "vuex";
import { eventBus } from "../../main";

export default {
  name: "TrailOptions",
  components: {},
  data() {
    return {
      enableDraggableCustomStopFinish: false,
      enableDraggableCustomStopFinishDisabled: false,
      xfrom: 1,
      xto: 1,
      openDesktopMenu: true,
      openMobileMenu: false,
      directionBooleanOptions: [
        { label: "Southbound", value: true },
        { label: "Northbound", value: false }
      ]
    };
  },
  computed: {
    ...mapState({
      sectionSetLookup: state => state.trail.sectionSetLookup,

      filteredSites: state => state.trail.filteredSites,

      allSets: state => state.trail.allSets,
      campsitesConfig: state => state.trail.campsitesConfig,
      campsitesLookup: state => state.trail.campsitesLookup,

      minCampOrderIndexVal: state => state.trail.minCampOrderIndexVal,
      maxCampOrderIndexVal: state => state.trail.maxCampOrderIndexVal
    }),
    currentSectionIndex: {
      get() {
        return this.$store.state.trail.currentSectionIndex;
      },
      set(value) {
        eventBus.$emit("custStartEnd_resetMarkers");

        this.$store.dispatch("trail_update_current_section_index", value);

        eventBus.$emit("custStartEnd_syncFromTo");
        eventBus.$emit("mapPath_focusOnTrail");
      }
    },

    fromCampOrderIndex: {
      get() {
        return this.$store.state.trail.fromCampOrderIndex;
      },
      set(value) {
        if (value === false || !this.campsitesLookup[value]) {
          value = this.minCampOrderIndexVal;
        }

        eventBus.$emit("custStartEnd_resetMarkers");
        this.$store.dispatch("trail_update_from", value);
        eventBus.$emit("custStartEnd_syncFromTo");
      }
    },
    currentSet: {
      get() {
        return this.$store.state.trail.currentSet;
      },
      set(value) {
        this.$store.dispatch("trail_update_current_set", value);
      }
    },
    toCampOrderIndex: {
      get() {
        return this.$store.state.trail.toCampOrderIndex;
      },
      set(value) {
        if (value === false || !this.campsitesLookup[value]) {
          value = this.maxCampOrderIndexVal;
        }
        eventBus.$emit("custStartEnd_resetMarkers");
        this.$store.dispatch("trail_update_to", value);

        eventBus.$emit("custStartEnd_syncFromTo");
      }
    },

    showCustomStartFinish: {
      get() {
        return this.$store.state.trail.showCustomStartFinish;
      },
      set(value) {
        this.$store.dispatch("trail_update_showCustomStartFinish", value);
        this.enableDraggableCustomStopFinishDisabled = true;
        setTimeout(() => {
          this.enableDraggableCustomStopFinishDisabled = false;
        }, 500);
      }
    },
    orderForward: {
      get() {
        return this.$store.state.trail.orderForward;
      },
      set(value) {
        this.$store.dispatch("trail_setOrder", value);
      }
    },
    skipCampById: {
      get() {
        return this.$store.state.trail.skipCampById;
      },
      set(value) {
        this.$store.dispatch("trail_update_skiplist_set", value);
        eventBus.$emit("map_clearAllPopups");
      }
    },

    campsitesArray() {
      let array = Object.values(this.campsitesLookup);

      array = array.map(row => {
        let { title, id, label } = row;
        return { title, id, label };
      });
      return array;
    },
    currentActiveSkip() {
      let skipCampById = this.skipCampById;

      try {
        let filteredSitesArray = this.filteredSites;

        let activeIds = {};
        filteredSitesArray.forEach(row => {
          activeIds[row.id] = true;
        });

        skipCampById = skipCampById.filter(row => {
          if (activeIds[row]) {
            return true;
          } else {
            return false;
          }
        });
      } catch (error) {
        skipCampById = this.skipCampById;
      }

      return skipCampById;
    }
  },
  created() {
    eventBus.$on("nav_trail_options", () => {
      this.$store.dispatch("trail_update_showCustomStartFinish", false);

      this.$store.dispatch("trail_reset_options");
    });
  },
  methods: {
    custStartEnd_showHideMarkers(options) {
      eventBus.$emit("custStartEnd_showHideMarkers", options);
    },

    update(position, oldValue) {
      setTimeout(() => {
        if (position == "start" && oldValue === this.fromCampOrderIndex) {
          console.log(position + " is same");
          eventBus.$emit("custStartEnd_syncFromTo", "start");
        } else if (position == "finish" && oldValue === this.toCampOrderIndex) {
          console.log(position + " is same");
          eventBus.$emit("custStartEnd_syncFromTo", "end");
        }
      }, 1);
    },
    onBreakpointChange() {
      this.openDesktopMenu = true;
      this.openMobileMenu = false;
    }
  },

  watch: {
    "$vuetify.breakpoint.name"(newBreakpoint, oldBreakpoint) {
      this.onBreakpointChange(newBreakpoint, oldBreakpoint);
    }
  }
};
</script>

<style lang="scss">
#topOfHelpSection img {
  max-width: 80%;
}

#trailControlWrapper {
  position: fixed; /* Fixes the div to the viewport */
  top: 57px; /* Aligns it to the top */
  left: 50%; /* Moves it to the center horizontally based on its parent */
  transform: translateX(-50%); /* Corrects for the width of the div */
  padding: 20px 0 0 20px;

  z-index: 1000; /* Ensure it's above other elements */
  width: 510px;
  border-radius: 4px;
  border: 2px solid #00000038 !important;
}

#filterSections {
  .v-select {
    width: 150px;
  }
}

#filterDirection {
  .v-select {
    width: 150px;
  }
}

#filterSkip {
  .v-select {
    width: 150px;
  }
}

#filterSections {
}

.startFinish {
  .forwardBackButtons {
    display: none;
  }

  .v-select {
    width: 272px;
    width: 230px;
    font-size: 13px;
  }
}

.v-btn--icon.doubleSqurebutton {
  border-radius: 2px;
  width: 53px;
  height: 30px;
}

.isTabletOrMobile {
  #trailControlWrapper {
    .floating-close-btn {
      display: unset;
    }

    width: 250px;

    .filterItem {
      .v-select {
        width: 198px;
        font-size: 14px;
      }
    }
  }
}

.isMobile {
  #trailControlWrapper {
    width: 100%;
    width: calc(100vw - 20px);

    .filterItem {
      .v-select {
        width: calc(100vw - 70px);

        font-size: 14px;
      }
    }
  }
}

#trailControlWrapper.closedMenu {
  width: auto;
  padding: 15px;
  width: 57px;
}
</style>
